import type { ButtonProps } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import type { FC } from 'react';

interface BigButtonProps extends ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  styleVariant?: BigButtonVariant;
}

export enum BigButtonVariant {
  Grey,
  Green,
  WhiteWithGreen,
}

export const BigButton: FC<BigButtonProps> = ({
  children,
  onClick,
  styleVariant = BigButtonVariant.Green,
  isLoading,
  ...rest
}) => (
  <Button
    onClick={onClick}
    fontWeight="700"
    fontSize="l"
    height="56px"
    borderRadius="100px"
    padding="16px 40px"
    mb={{ base: '24px', md: '40px' }}
    {...variants[styleVariant]}
    isLoading={isLoading}
    {...rest}
  >
    {children}
  </Button>
);

const variants = {
  [BigButtonVariant.Green]: {
    colorScheme: 'green',
    color: 'white',
  },
  [BigButtonVariant.WhiteWithGreen]: {
    backgroundColor: 'whiteAlpha.700',
    color: 'green.500',
  },
  [BigButtonVariant.Grey]: {
    colorScheme: 'black',
    backgroundColor: '#303030',
    color: 'white',
  },
};
